import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import { graphql, navigate } from 'gatsby';
import React, { useEffect } from 'react';
import LoadRenewalWrapper from 'components/ApiRequestWrapper/LoadRenewalWrapper';
import CheckYourDetailsPage from 'components/CheckDetails';
import Layout from 'components/Layout';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import { isQuoteSummaryValid } from 'helpers/quoteSummaryValidation';
import { renewalRoutes } from 'helpers/routingHelper';
import { usePageTracking } from 'helpers/usePageTracking';
import withPolicyNumber, { PolicyNumberProps } from 'helpers/withPolicyNumber';
import { isRenewal, useQuote } from 'state/quote/quote';
import { useUser } from 'state/user/state';
import { CsHero, CsMeta } from 'types/contentStack';

type CheckYourDetailsProps = {
  data: {
    csCheckYourDetails: {
      meta: CsMeta;
      hero_renewal: CsHero;
    };
  };
};

export const query = graphql`
  query {
    csCheckYourDetails {
      meta {
        meta_title
      }
      hero_renewal {
        heading
        subheading
      }
    }
  }
`;

const CheckYourDetails: React.FC<CheckYourDetailsProps & PolicyNumberProps> = ({
  data: {
    csCheckYourDetails: {
      meta,
      hero_renewal: { heading, subheading },
    },
  },
  policyNumber,
}) => {
  const quote = useQuote();

  const [user] = useUser();
  const isValidQuoteForTracking = !!quote && isQuoteSummaryValid(quote);
  usePageTracking(meta.meta_title, !!user?.isLoggedIn && isValidQuoteForTracking);

  useEffect(() => {
    if (quote) {
      if (!isRenewal(quote) || quote.renewalStatus !== 'InProgress') {
        throw new Error('Returned quote does not have renewal status "InProgress"');
      } else if (!isQuoteSummaryValid(quote)) {
        navigate(renewalRoutes.quoteSummary(quote.policyNumber));
      }
    }
  }, [quote]);

  useCheckoutTracking(6, quote, 'Renewals');

  return (
    <Layout meta={meta} pageType="mtaAndRenewal">
      <LoadRenewalWrapper policyNumber={policyNumber}>
        {quote && (
          <>
            <HeroPlain heading={heading} subhead={subheading || undefined} />
            <CheckYourDetailsPage quote={quote} />
          </>
        )}
      </LoadRenewalWrapper>
    </Layout>
  );
};

export default withPolicyNumber(CheckYourDetails);
